<template>
  <div
    id="navb"
    toggleable="lg"
  >
    <div
      v-if="isDevOrStaging"
      class="devBanner fw-bold text-center container"
    >
      {{ appMode }}
    </div>
    <div
      id="navbar-container"
      class="container d-flex"
    >
      <div class="col-lg-1 col-3 col-sm-2">
        <a href="https://musikverlag.orf.at/">
          <img
            id="logo"
            src="https://musikverlag.orf.at/typo3conf/ext/gd_theme/Resources/Public/images/logo.png"
            class="d-inline-block align-top"
            alt="Logo"
            width="72"
            height="72"
          >
        </a>
      </div>
      <div class="col-sm col-auto d-flex align-items-center">
        <a
          class="navbar-brand text-white"
          href="https://musikverlag.orf.at/"
        >ORF-ENTERPRISE Musikverlag</a>
      </div>
      <div class="col-lg col-1 d-lg-block d-flex">
        <button
          class="btn defaultTableButton d-flex d-lg-none align-self-center"
          @click="show = !show"
        >
          <img
            src="@/assets/menu-default.svg"
            class="mvIcon-md"
          >
        </button>
        <div class="justify-content-end d-none d-lg-flex text-white mb-3">
          <router-link
            v-if="username == ''"
            class="cmr-1 cpr-1 mvNavItem mvNavItemBorder"
            custom
            :to="{ name: $routesNames.login }"
          >
            {{ $t("navLogin") }}
          </router-link>
          <router-link
            v-if="username == ''"
            class="cml-1 cpr-1 mvNavItem"
            custom
            :to="{ name: $routesNames.register }"
          >
            {{ $t("navRegister") }}
          </router-link>
          <span
            v-if="username != ''"
            class="cmr-1 cpr-1 mvNavItem mvNavItemBorder"
            disabled
          >
            {{ $t("navbarGreeting") }}{{ username }}
          </span>
          <router-link
            v-if="username != ''"
            class="cmr-1 cpr-1 mvNavItem mvNavItemBorder"
            custom
            :to="{ name: $routesNames.editAccount }"
          >
            {{ $t("navAccount") }}
          </router-link>
          <router-link
            v-if="username != ''"
            class="cmr-1 cpr-1 mvNavItem"
            custom
            :to="{ name: $routesNames.logout }"
          >
            {{ $t("navLogout") }}
          </router-link>
        </div>
        <div class="justify-content-end d-none d-lg-flex">
          <b-dropdown
            toggle-class="dropDownMenuButton"
            no-caret
          >
            <template #button-content>
              {{ $t("navDropDownTitleMusiclibrary") }}
              <i
                class="bi bi-chevron-down"
                style="color: #ed008c"
              />
            </template>
            <b-dropdown-item
              v-for="(item, index) in navMusiclibraryDropDownDE"
              :key="index"
              class="text-uppercase"
              :href="item.route"
            >
              {{ item.name }}
            </b-dropdown-item>
          </b-dropdown>
          <b-dropdown
            toggle-class="dropDownMenuButton"
            no-caret
          >
            <template #button-content>
              {{ $t("navDropDownTitleShoppingcart") }}
              <i
                class="bi bi-chevron-down"
                style="color: #ed008c"
              />
            </template>
            <b-dropdown-item
              v-for="(item, index) in navSammelkorbDropDownDE"
              :key="index"
              class="text-uppercase"
              :href="item.route"
            >
              {{ item.name }}
            </b-dropdown-item>
          </b-dropdown>
          <b-dropdown
            toggle-class="dropDownMenuButton"
            no-caret
          >
            <template #button-content>
              {{ $t("navDropDownTitleMusikverlag") }}
              <i
                class="bi bi-chevron-down"
                style="color: #ed008c"
              />
            </template>
            <b-dropdown-item
              v-for="(item, index) in navMusikverlagDropDownDE"
              :key="index"
              class="text-uppercase"
              :href="item.route"
            >
              {{ item.name }}
            </b-dropdown-item>
          </b-dropdown>
          <b-dropdown
            v-if="isMVemployee"
            toggle-class="dropDownMenuButton"
            no-caret
          >
            <template #button-content>
              {{ $t("navDropDownTitleAdmin") }}
              <i
                class="bi bi-chevron-down"
                style="color: #ed008c"
              />
            </template>
            <b-dropdown-item
              v-for="(item, index) in navAdminDropDownDe"
              :key="index"
              class="text-uppercase"
              :href="item.route"
            >
              {{ item.name }}
            </b-dropdown-item>
          </b-dropdown>

          <b-dropdown
            toggle-class="dropDownMenuButton"
            no-caret
          >
            <template #button-content>
              {{ $t("navDropDownTitleLabel") }}
              <i
                class="bi bi-chevron-down"
                style="color: #ed008c"
              />
            </template>
            <b-dropdown-item
              v-for="(item, index) in navLabelDropDownDE"
              :key="index"
              class="text-uppercase"
              :href="item.route"
            >
              {{ item.name }}
            </b-dropdown-item>
          </b-dropdown>
          <b-dropdown
            offset="-150"
            toggle-class="dropDownMenuButton"
            no-caret
          >
            <template #button-content>
              {{ $t("navDropDownTitleHelp") }}
              <i
                class="bi bi-chevron-down"
                style="color: #ed008c"
              />
            </template>
            <b-dropdown-item
              v-for="(item, index) in navHelpDropDownDE"
              :key="index"
              class="text-uppercase"
              :href="item.route"
            >
              {{ item.name }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
    <div
      v-if="show"
      class="d-lg-none d-flex flex-column text-left navbar-small"
    >
      <button
        class="dropDownMenuButton-sm"
        @click="drowDownMenu2 = !drowDownMenu2"
      >
        {{ $t("navDropDownTitleMusiclibrary") }}
        <i
          v-if="!drowDownMenu2"
          class="bi bi-chevron-down"
          style="color: #ed008c"
        />
        <i
          v-if="drowDownMenu2"
          class="bi bi-chevron-up"
          style="color: #ed008c"
        />
      </button>
      <div
        v-if="drowDownMenu2"
        class="d-flex flex-column"
      >
        <a
          v-for="(item, index) in navMusiclibraryDropDownDE"
          :key="index"
          :href="item.route"
        >{{ item.name }}</a>
      </div>
      <button
        class="dropDownMenuButton-sm"
        @click="drowDownMenu3 = !drowDownMenu3"
      >
        {{ $t("navDropDownTitleShoppingcart") }}
        <i
          v-if="!drowDownMenu3"
          class="bi bi-chevron-down"
          style="color: #ed008c"
        />
        <i
          v-if="drowDownMenu3"
          class="bi bi-chevron-up"
          style="color: #ed008c"
        />
      </button>
      <div
        v-if="drowDownMenu3"
        class="d-flex flex-column"
      >
        <a
          v-for="(item, index) in navSammelkorbDropDownDE"
          :key="index"
          :href="item.route"
        >{{ item.name }}</a>
      </div>
      <button
        class="dropDownMenuButton-sm"
        @click="drowDownMenu1 = !drowDownMenu1"
      >
        {{ $t("navDropDownTitleMusikverlag") }}
        <i
          v-if="!drowDownMenu1"
          class="bi bi-chevron-down"
          style="color: #ed008c"
        />
        <i
          v-if="drowDownMenu1"
          class="bi bi-chevron-up"
          style="color: #ed008c"
        />
      </button>
      <div
        v-if="drowDownMenu1"
        class="d-flex flex-column"
      >
        <a
          v-for="(item, index) in navMusikverlagDropDownDE"
          :key="index"
          :href="item.route"
        >{{ item.name }}</a>
      </div>
      <button
        v-if="isMVemployee"
        class="dropDownMenuButton-sm"
        @click="drowDownMenu6 = !drowDownMenu6"
      >
        {{ $t("navDropDownTitleAdmin") }}
        <i
          v-if="!drowDownMenu6"
          class="bi bi-chevron-down"
          style="color: #ed008c"
        />
        <i
          v-if="drowDownMenu6"
          class="bi bi-chevron-up"
          style="color: #ed008c"
        />
      </button>
      <div
        v-if="drowDownMenu6 && isMVemployee"
        class="d-flex flex-column"
      >
        <a
          v-for="(item, index) in navAdminDropDownDe"
          :key="index"
          :href="item.route"
        >{{ item.name }}</a>
      </div>


      <button
        class="dropDownMenuButton-sm"
        @click="drowDownMenu4 = !drowDownMenu4"
      >
        {{ $t("navDropDownTitleLabel") }}
        <i
          v-if="!drowDownMenu4"
          class="bi bi-chevron-down"
          style="color: #ed008c"
        />
        <i
          v-if="drowDownMenu4"
          class="bi bi-chevron-up"
          style="color: #ed008c"
        />
      </button>
      <div
        v-if="drowDownMenu4"
        class="d-flex flex-column"
      >
        <a
          v-for="(item, index) in navLabelDropDownDE"
          :key="index"
          :href="item.route"
        >{{ item.name }}</a>
      </div>

      <button
        class="dropDownMenuButton-sm"
        @click="drowDownMenu5 = !drowDownMenu5"
      >
        {{ $t("navDropDownTitleHelp") }}
        <i
          v-if="!drowDownMenu5"
          class="bi bi-chevron-down"
          style="color: #ed008c"
        />
        <i
          v-if="drowDownMenu5"
          class="bi bi-chevron-up"
          style="color: #ed008c"
        />
      </button>
      <div
        v-if="drowDownMenu5"
        class="d-flex flex-column"
      >
        <a
          v-for="(item, index) in navHelpDropDownDE"
          :key="index"
          :href="item.route"
        >{{ item.name }}</a>
      </div>
      <router-link
        v-if="username == ''"
        custom
        :to="{ name: $routesNames.login }"
      >
        {{ $t("navLogin") }}
      </router-link>
      <router-link
        v-if="username == ''"
        custom
        :to="{ name: $routesNames.register }"
      >
        {{ $t("navRegister") }}
      </router-link>
      <span
        v-if="username != ''"
        class=""
        disabled
      >
        {{ $t("navbarGreeting") }}{{ username }}
      </span>
      <router-link
        v-if="username != ''"
        custom
        :to="{ name: $routesNames.editAccount }"
      >
        {{ $t("navAccount") }}
      </router-link>
      <router-link
        v-if="username != ''"
        custom
        :to="{ name: $routesNames.logout }"
      >
        {{ $t("navLogout") }}
      </router-link>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { CREDENTIAL_COOKIE_IDENTIFIER_KEY } from "@/util/constants";
import {
  navMusikverlagDropDownDE,
  navMusiclibraryDropDownDE,
  navSammelkorbDropDownDE,
  navLabelDropDownDE,
  navHelpDropDownDE,
  navAdminDropDownDe,
} from "@/util/constants";
import Cookies from "js-cookie";
import { CredentialCookie } from "../util/classes";

@Component
// This component represents the navbar.
export default class NavBar extends Vue {
  data() {
    const navMusikverlagDropDownDEC = navMusikverlagDropDownDE;
    const navMusiclibraryDropDownDEC = navMusiclibraryDropDownDE;
    const navSammelkorbDropDownDEC = navSammelkorbDropDownDE;
    const navLabelDropDownDEC = navLabelDropDownDE;
    const navHelpDropDownDEC = navHelpDropDownDE;
    const navAdminDropDownDeC = navAdminDropDownDe;
    return {
      // Gets or sets the routes for the first dropdown.
      navMusikverlagDropDownDE: navMusikverlagDropDownDEC,

      // Gets or sets the routes for the second dropdown.
      navMusiclibraryDropDownDE: navMusiclibraryDropDownDEC,

      // Gets or sets the routes for the third dropdown.
      navSammelkorbDropDownDE: navSammelkorbDropDownDEC,

      // Gets or sets the routes for the fourth dropdown.
      navLabelDropDownDE: navLabelDropDownDEC,

      // Gets or sets the routes for the fifth dropdown.
      navHelpDropDownDE: navHelpDropDownDEC,

      // Gets or sets the routes for the admin dropdown.
      navAdminDropDownDe: navAdminDropDownDeC,

      // Gets or sets a boolean indicating whether to show the mobile version of the navbar.
      show: false,

      // Gets or sets a boolean indicating whether to show the mobile version of the first dropdown.
      drowDownMenu1: false,

      // Gets or sets a boolean indicating whether to show the mobile version of the second dropdown.
      drowDownMenu2: false,

      // Gets or sets a boolean indicating whether to show the mobile version of the third dropdown.
      drowDownMenu3: false,

      // Gets or sets a boolean indicating whether to show the mobile version of the fourth dropdown.
      drowDownMenu4: false,

      // Gets or sets a boolean indicating whether to show the mobile version of the fifth dropdown.
      drowDownMenu5: false,

      // Gets or sets a boolean indicating wehether to show the mobile version of the admin dropdown.
      drowDownMenu6: false,
    };
  }
  // Gets a boolean indicating wether the user is an mv employee or not.
  get isMVemployee(): boolean {
    const credCookie = Cookies.get(CREDENTIAL_COOKIE_IDENTIFIER_KEY);

    if (credCookie === undefined) {
      return false;
    }
    const cookieOBJ = JSON.parse(credCookie) as CredentialCookie;

    return cookieOBJ.isMVuser;
  }
  // This getter returns the name of the app environment.
  // This is used in development or staging to distinguish it from production.
  get appMode(): string {
    return process.env.VUE_APP_ENV;
  }
  // This getter returns a boolean indicating whether the app is in production or not in production.
  get isDevOrStaging(): boolean {
    switch (process.env.VUE_APP_ENV) {
      case "Production":
        return false;
      default:
        return true;
    }
  }
  // This getter returns the username of the logged in user.
  get username(): string {
    if (Cookies.get(CREDENTIAL_COOKIE_IDENTIFIER_KEY) == undefined) {
      return "";
    }

    const user = JSON.parse(
      Cookies.get(CREDENTIAL_COOKIE_IDENTIFIER_KEY)!
    ) as CredentialCookie;
    return user.userName;
  }
}
</script>
<style lang="scss">
.devBanner {
  background-color: yellow;
  color: black;
}
#navb {
  background-color: black;
  border-bottom: 1px solid #13adf0;
  padding-bottom: 0.5em;
  padding-top: 0.5em;
}
.mvNavItem {
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-size: small;
}
.mvNavItemBorder {
  border-right: 1px solid white;
}
.dropDownMenuButton {
  background-color: transparent !important;
  color: white !important;
  border: none !important;
  text-transform: uppercase !important;
}
.dropDownMenuButton:hover,
.dropDownMenuButton:focus,
.dropDownMenuButton:active {
  color: #13adf0 !important;
  background-color: transparent !important;
  border: none !important;
}
.dropDownMenuButton-sm {
  background-color: white !important;
  color: black !important;
  border: 1px solid lightgray !important;
  text-transform: uppercase !important;
  border-bottom: 2px solid lightgray;
  padding-top: 0.625em;
  padding-bottom: 0.625em;
}
.dropDownMenuButton-sm:hover,
.dropDownMenuButton-sm:focus,
.dropDownMenuButton-sm:active {
  color: #13adf0 !important;
  background-color: white !important;
}
.navbar-small a {
  text-decoration: none;
  color: black;
  background-color: white;
  border-top: 1px solid lightgray;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  padding-left: 1em;
  text-transform: uppercase;
}
.navbar-small span {
  text-decoration: none;
  font-weight: bold;
  color: black;
  background-color: white;
  border-top: 1px solid lightgray;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  padding-left: 1em;
  text-transform: uppercase;
}
@media (max-width: 438px) {
  .navbar-brand {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1rem !important;
    text-decoration: none;
    white-space: nowrap;
  }
}
</style>
